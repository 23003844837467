import * as React from 'react'
import { StyleSheet, View } from 'react-native'
import { SignIn } from '@clerk/nextjs'
import { ActivityFooter } from '@components/Fillers/ActivityFooter'
import { useReferral } from '@features/referral/useReferral'
import { clerkAppearanceOptions } from '@themes/ClerkThemeOptions'
import * as Colors from '@themes/Colors'
import { useClerk } from '~/universal/clerk'

export default function ClerkLoginPage() {
  const { loaded } = useClerk()
  const { query } = useReferral()

  return (
    <View
      style={{
        ...StyleSheet.absoluteFillObject,
        backgroundColor: Colors.backgroundGray,
      }}
    >
      <View style={{ flexGrow: 1, flexShrink: 1, flexBasis: '0%' }}>
        <View style={{ flexGrow: 1 - Math.sqrt(5) / 2 }} />
        {loaded && query.status !== 'loading' ? (
          <SignIn appearance={{ ...clerkAppearanceOptions }} />
        ) : (
          <ActivityFooter active />
        )}
      </View>
    </View>
  )
}
